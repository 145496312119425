import React from 'react';
import { Chart } from "react-google-charts";
import "./dashboard.css";
// Mapping full month names to abbreviated versions
const monthMap = {
    "January": "Jan",
    "February": "Feb",
    "March": "Mar",
    "April": "Apr",
    "May": "May",
    "June": "Jun",
    "July": "Jul",
    "August": "Aug",
    "September": "Sep",
    "October": "Oct",
    "November": "Nov",
    "December": "Dec"
};

const allMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const BarPlanActualChart = ({ groupedData }) => {
    const headers = ["Month", "Plan", "Sales", "Miss Plan",];


    const data = [
        headers,
        ...allMonths.map(month => {
            const fullMonthName = groupedData && Object.keys(groupedData).find(fullMonth => monthMap[fullMonth] === month);

            const monthData = groupedData && groupedData[fullMonthName] || []; // Default to an empty array if no data for the month

            const plan = monthData.length > 0 ? parseInt(monthData[0]?.planamount) : 0;
            const totalSales = monthData.length > 0 ? parseInt(monthData[0]?.totalsales) : 0;
            const missPlan = monthData.length > 0 ? parseInt(monthData[0]?.missplantytotalsale) : 0;

            return [month, plan, totalSales, missPlan];
        })
    ];


    return (
        <>
            <Chart
                chartType="Bar"
                data={data}
            // options={{
            //     isStacked: false,
            //     // legend: { position: "bottom" },
            // }}
            />

        </>
    );
};

export const BarAucChart = ({ groupedData }) => {

    const headers = groupedData ? Object.keys(groupedData) : [];

    const allYears = Array.from(
        new Set(
            headers.flatMap(gender => groupedData[gender]?.map(item => item.yearr) || [])
        )
    ).sort();

    const data = [
        ["Gender", ...allYears], // Header row
        ...headers.map(gender => {
            const monthData = groupedData[gender] || [];

            // Create an object for quick lookup of auc values by year
            const yearAucMap = monthData.reduce((acc, entry) => {
                acc[entry.yearr] = entry.auc;
                return acc;
            }, {});

            // Populate each year column with the auc value or 0 if missing
            const row = [gender, ...allYears.map(year => parseInt(yearAucMap[year]) || 0)];
            return row;
        })
    ];


    return (
        <Chart
            chartType="Bar"
            data={data}
            options={{
                isStacked: false,
                legend: { position: "bottom" },
            }}
        />
    );
};

export const BarSaleByMonthChart = ({ groupedData }) => {
    const headers = groupedData ? Object.keys(groupedData) : [];

    // Extract all unique years from groupedData
    const allYears = Array.from(
        new Set(
            headers.flatMap(gender => groupedData[gender]?.map(item => item.yearr) || [])
        )
    ).sort();


    const data = [
        ["Month", ...allYears],
        ...allMonths.map(month => {
            const fullMonthName = groupedData && Object.keys(groupedData).find(fullMonth => monthMap[fullMonth] === month);

            const monthData = groupedData && groupedData[fullMonthName] || []; // Default to an empty array if no data for the month

            // Create an object for quick lookup of auc values by year
            const yearAucMap = monthData.reduce((acc, entry) => {
                acc[entry.yearr] = entry.net_amount;
                return acc;
            }, {});

            // Populate each year column with the auc value or 0 if missing
            const row = [month, ...allYears.map(year => parseInt(yearAucMap[year]) || 0)];
            return row
        })
    ];

    return (
        <Chart
            chartType="Bar"
            data={data}
            options={{
                isStacked: false,
                legend: { position: "bottom" },
                // hAxis: { title: "Months" },
                vAxis: {
                    title: "Net Amount",
                    minValue: 0, // Set your custom minimum value here
                    maxValue: 1222, // Set your custom maximum value here based on your data range
                },
            }}
        />
    );
};

export const BarReturnRateChart = ({ groupedData }) => {
    const headers = ["Month", "Shipped", "Returned", "Return Percent"];

    const data = [
        headers,
        ...allMonths.map(month => {
            const fullMonthName = groupedData && Object.keys(groupedData).find(fullMonth => monthMap[fullMonth] === month);
            const monthData = groupedData && groupedData[fullMonthName] || []; // Default to an empty array if no data for the month

            const shipped = monthData.length > 0 ? parseInt(monthData[0]?.shipped) : 0;
            const returned = monthData.length > 0 ? -parseInt(monthData[0]?.returned) : 0;  // Returned values are now negative
            const return_percent = monthData.length > 0 ? parseInt(monthData[0]?.return_percent) : 0;

            return [month, shipped, returned, return_percent];
        })
    ];

    const options = {
        // hAxis: {
        //     title: 'Month',
        // },
        vAxes: {
            0: {
                title: 'Shipped and Returned',
                minValue: -Math.max(...data.map(item => Math.abs(item[2]))) // Adjust the negative axis to accommodate "Returned"
            },
            1: { title: 'Return Percent' }, // Right axis for return percent
        },
        seriesType: 'bars', // Default series type is bars
        series: {
            0: { targetAxisIndex: 0 }, // Shipped series will use the left axis
            1: { targetAxisIndex: 0 }, // Returned series will use the left axis
            2: { type: 'line', targetAxisIndex: 1 }, // Return Percent as line on the right axis
        },
        bar: { groupWidth: '60%' },  // Control the width of bars
    };

    return (
        <div>
            <Chart
                chartType="ComboChart"
                width="100%"
                height="400px"
                data={data}
                options={options}
            />
        </div>
    );
};

export const BarDivisionChart = ({ groupedData }) => {
    // Convert groupedData into Pie Chart data format
    const data = [
        ["Gender", "Net Amount"],
        ...Object.entries(groupedData).map(([gender, records]) => {
            const totalNetAmount = records.reduce((sum, record) => sum + parseInt(record.net_amount, 10), 0);
            return [gender, totalNetAmount];
        })
    ];

    const options = {
        pieHole: 0.4,
        is3D: true,
        pieStartAngle: 100,
        sliceVisibilityThreshold: 0.02,
        legend: {
            position: "top", // Positions legend at the top
            alignment: "center",
            maxLines: 3, // Limit number of lines (if available in your context)
            textStyle: {
                color: "#233238",
                fontSize: 12, // Reduce font size to fit more items
            },
        },
        colors: ["#375a84", "#36a3eb", "#ffcf56"],
    };

    return (
        <Chart
            chartType="PieChart"
            data={data}
            options={options}
            width={"100%"}
            height={"400px"}
        />
    );
};

export const BarAccountTypeChart = ({ groupedData }) => {
    // Convert groupedData into Pie Chart data format
    const data = [
        ["Gender", "Net Amount"],
        ...Object.entries(groupedData).map(([gender, records]) => {
            const totalNetAmount = records.reduce((sum, record) => sum + parseInt(record.net_amount, 10), 0);
            return [gender, totalNetAmount];
        })
    ];

    const options = {
        pieHole: 0.4,
        is3D: true,
        pieStartAngle: 100,
        sliceVisibilityThreshold: 0,
        legend: {
            position: "top", // Positions legend at the top
            alignment: "center",
            maxLines: 3, // Limit number of lines (if available in your context)
            textStyle: {
                color: "#233238",
                fontSize: 12, // Reduce font size to fit more items
            },
        },
        colors: ["#375a84", "#36a3eb", "#ffcf56"],
    };

    return (
        <Chart
            chartType="PieChart"
            data={data}
            options={options}
            width={"100%"}
            height={"400px"}
        />
    );
};