import React, { useEffect, useState } from 'react'
import "./dashboard.css"
import { Col, Row, Skeleton, Tag } from "antd";
import { Typography } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import { TabTitle } from '../../utils/GeneralFunction';

import {
    Chart as ChartJS, ArcElement, Tooltip, Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,

} from 'chart.js';
import { getGender } from '../../redux/actions/gender.action';
import { useDispatch, useSelector } from 'react-redux';
import { ReadCookie } from '../../utils/readCookie';
import { getAccountTypeStats, getAucStats, getDashboardStats, getDivisionStats, getPlanActualStats, getReturnRateStats, getSalesByMonthStats } from '../../redux/actions/stats.action';

import { BarAccountTypeChart, BarAucChart, BarDivisionChart, BarPlanActualChart, BarReturnRateChart, BarSaleByMonthChart } from './Charts';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    LineController,
    BarController,
    // ChartDataLabels
);

const { Title } = Typography;


export default function Dashboard() {

    TabTitle("Dashboard");
    const { gender, dashboardStats,
        divisionStats,
        accountTypeStats,
        salesByMonthStats,
        returnRateStats,
        aucStats,
        actualPlanStats,
    } = useSelector((state) => state);

    const dispatch = useDispatch();
    const menuId = ReadCookie("menuId");
    const token = ReadCookie("token");


    const [filter, setFilter] = useState({
        gender: [],
        year: ["2023", "2024", "2025"],
        customer_type_id: [2, 1, 14, 3, 13, 10]
    });

    const filteredDivisions = divisionStats?.payload?.data.filter(x => filter.gender.includes(x.gender_id) && filter.year.includes(x.yearr));
    const filterAccountType = accountTypeStats?.payload?.data.filter((x) => filter.customer_type_id.includes(x.customer_type_id) && filter.year.includes(x.yearr));
    const filterSalesByMonth = salesByMonthStats?.payload?.data.filter((x) => filter.year.includes(x.yearr));
    const filterReturnRate = returnRateStats?.payload?.data.filter((x) => filter.year.includes(x.yearr));
    const filterAuc = aucStats?.payload?.data.filter(x => filter.gender.includes(x.gender_id) && filter.year.includes(x.yearr));
    const filterPlanActual = actualPlanStats?.payload?.data.filter(x => filter.gender.includes(x.gender_id) && filter.year.includes(x.yearr));

    useEffect(() => {
        let isMounted = true;
        dispatch(getGender(token, menuId)).then((val) => {
            if (isMounted && val.type === "GET_GENDER_SUCCESS") {
                let data = val.payload.data;
                let gender_ids = data.filter((x) => !["Digital", "Gifting", "N/A"].includes(x.gender_name)).map(item => item.gender_id);
            
                setFilter((prevFilter) => ({
                    ...prevFilter,
                    gender: gender_ids,
                }));
                dispatch(getDashboardStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));

                dispatch(getDivisionStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getAccountTypeStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getSalesByMonthStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getReturnRateStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getAucStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
                dispatch(getPlanActualStats(token, { year: filter.year.toString(), gender_id: gender_ids.toString(), customer_type_id: filter.customer_type_id.toString() }));
            }
        });
        // Cleanup function to set isMounted to false when unmounted
        return () => {
            isMounted = false;
        };
    }, [dispatch, token, menuId]);


    const groupedDivision = filteredDivisions?.reduce((acc, item) => {
        const { gender_name } = item;
        if (!acc[gender_name]) {
            acc[gender_name] = [];
        }
        acc[gender_name].push(item);
        return acc;
    }, {});

    const groupedAccountType = filterAccountType?.reduce((acc, item) => {
        const { customer_type_name } = item;
        if (!acc[customer_type_name]) {
            acc[customer_type_name] = [];
        }
        acc[customer_type_name].push(item);
        return acc;
    }, {});
    const groupedSalesByMonth = filterSalesByMonth?.reduce((acc, item) => {
        const { month_name } = item;
        if (!acc[month_name]) {
            acc[month_name] = [];
        }
        acc[month_name].push(item);
        return acc;
    }, {});

    const groupedReturnRate = filterReturnRate?.reduce((acc, item) => {
        const { month_name } = item;
        if (!acc[month_name]) {
            acc[month_name] = [];
        }
        acc[month_name].push(item);
        return acc;
    }, {});


    const groupedAUC = filterAuc?.reduce((acc, item) => {
        const { gender_name } = item;
        if (!acc[gender_name]) {
            acc[gender_name] = [];
        }
        acc[gender_name].push(item);
        return acc;
    }, {});

    const groupedPlanActual = filterPlanActual?.reduce((acc, item) => {
        const { month_name } = item;
        if (!acc[month_name]) {
            acc[month_name] = [];
        }
        acc[month_name].push(item);
        return acc;
    }, {});

    // const divisionLabel = groupedDivision && Object.keys(groupedDivision);
    // const accountTypeLabel = groupedAccountType && Object.keys(groupedAccountType);


    // const divisionData = divisionLabel?.map(gender =>
    //     groupedDivision[gender].reduce((sum, item) => sum + parseInt(item.net_amount, 10), 0)
    // );

    // const accountTypeData = accountTypeLabel?.map(accounType =>
    //     groupedAccountType[accounType].reduce((sum, item) => sum + parseInt(item.net_amount, 10), 0)
    // );



    // RETURN RATE

    // Define a fixed color palette
    const fixedColors = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(98, 106, 86, 0.2)',
        'rgba(120, 126, 186, 0.2)',
        'rgba(120, 16, 56, 0.2)',
        'rgba(120, 56, 186, 0.2)',
    ];

    // Helper function to assign colors without duplicates
    const generateColors = (numItems) => {
        const colors = [];
        for (let i = 0; i < numItems; i++) {
            colors.push(fixedColors[i % fixedColors.length]);
        }
        return colors;
    };



    const labels = (labels, data) => {
        return labels?.map((label, index) => {
            return (
                <div key={index}>
                    <p className='dashboard-chart-label'><span style={{ background: generateColors(labels?.length)[index], padding: "3px 15px 3px 15px", marginRight: 5 }}></span>{label} - {data[index]}</p>
                </div>
            )
        })
    }



    const customerTypeId = [
        {
            value: "Majors",
            id: 2
        },
        {
            value: "Speciality",
            id: 1
        },
        {
            value: "DS",
            id: 14
        },
        {
            value: "E-Commerce",
            id: 3
        },
        {
            value: "International",
            id: 13
        },
        {
            value: "Off Price",
            id: 10
        },
    ]


    return (
        <div className='dashboard-container'>
            <div className='dashboard-hd-main'>Dashboard</div>
            <div className='dashboard_filters_container'>
                <div>
                    {
                        gender?.payload?.data.filter((x) => !["Digital", "Gifting", "N/A"].includes(x.gender_name)).map((item, idx) => {
                            return (
                                <Tag key={idx} className={`dashboard_tag ${filter.gender.includes(item.gender_id) && 'dashboard_tag_active'}`} onClick={() => {
                                    if (filter.gender.includes(item.gender_id)) {
                                        let removeGender = filter.gender.filter((v) => v !== item.gender_id);
                                        setFilter({ ...filter, gender: removeGender })
                                    } else {
                                        setFilter({ ...filter, gender: [...filter.gender, item.gender_id] })
                                    }
                                }}>
                                    {item.gender_name}
                                </Tag>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        customerTypeId.map((val, idx) => {
                            return (
                                <Tag key={idx} className={`dashboard_tag ${filter.customer_type_id.includes(val.id) && 'dashboard_tag_active'}`} onClick={() => {
                                    if (filter.customer_type_id.includes(val.id)) {
                                        let removeCustomerTypeId = filter.customer_type_id.filter((v) => v !== val.id);
                                        setFilter({ ...filter, customer_type_id: removeCustomerTypeId })
                                    } else {
                                        setFilter({ ...filter, customer_type_id: [...filter.customer_type_id, val.id] })
                                    }
                                }}>
                                    {val.value}
                                </Tag>
                            )
                        })
                    }
                </div>
                <div>
                    {
                        ["2023", "2024", "2025"].map((val, idx) => {
                            return (
                                <Tag key={idx} className={`dashboard_tag ${filter.year.includes(val) && 'dashboard_tag_active'}`} onClick={() => {
                                    if (filter.year.includes(val)) {
                                        let removeYear = filter.year.filter((v) => v !== val);
                                        setFilter({ ...filter, year: removeYear })
                                    } else {
                                        setFilter({ ...filter, year: [...filter.year, val] })
                                    }
                                }}>
                                    {val}
                                </Tag>
                            )
                        })
                    }
                </div>


            </div>

            <div>
                <Row className='mb-20' gutter={[{ xs: 6, sm: 12, md: 16, lg: 24, xl: 30, xxl: 32 }, { xs: 6, sm: 12, md: 16, lg: 24, xl: 30, xxl: 32 }]}
                >
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} className="gutter-row mb-4">
                        <Card title="Total Users" trendValue={0} trendType="up" value={0} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} className="gutter-row mb-4">
                        <Card title="Total Active Customer" trendValue={0} trendType="up" value={0} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} className="gutter-row mb-4">
                        <Card title="Total Sales" trendValue={0} trendType="up" value={0} />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6} className="gutter-row mb-4">
                        <Card title="Total Sales Target" trendValue={0} trendType="up" value={0} />
                    </Col>

                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row">
                        {
                            divisionStats.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ minHeight: 400, display: "flex", justifyContent: "center" }}>
                                    <div className='dashboard-card-conten'>
                                        <div className='dashboard-card-hd text-center'>
                                            DIVISION
                                        </div>
                                        {
                                            groupedDivision && Object.keys(groupedDivision)?.length > 0 ? (
                                                <div className='dashboard-chart-container'>
                                                    <div>
                                                        <BarDivisionChart groupedData={groupedDivision} />

                                                        {/* <Pie data={pieSettings(divisionLabel, divisionData, generateColors(divisionLabel?.length))} /> */}
                                                    </div>
                                                    {/* <div>
                                                        {
                                                            labels(divisionLabel, divisionData)
                                                        }
                                                    </div> */}
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }


                                    </div>
                                </div>
                            )
                        }
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row">
                        {
                            accountTypeStats.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ minHeight: 400, display: "flex", justifyContent: "center" }}>
                                    <div className='dashboard-card-conten'>
                                        <div className='dashboard-card-hd text-center'>
                                            Account Type
                                        </div>
                                        {
                                            groupedAccountType && Object.keys(groupedAccountType)?.length > 0 ? (
                                                <div className='dashboard-chart-container'>
                                                    <div>
                                                        <BarAccountTypeChart groupedData={groupedAccountType} />

                                                    </div>
                                                    {/* <div>
                                                        <Pie data={pieSettings(accountTypeLabel, accountTypeData, generateColors(accountTypeLabel?.length))} />
                                                    </div>
                                                    <div>
                                                        {
                                                            labels(accountTypeLabel, accountTypeData)
                                                        }
                                                    </div> */}
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            salesByMonthStats.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ minHeight: 400, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            Sales By Month
                                        </div>
                                        {
                                            groupedSalesByMonth && Object.keys(groupedSalesByMonth)?.length > 0 ? (
                                                <div >
                                                    <BarSaleByMonthChart groupedData={groupedSalesByMonth} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }


                                    </div>
                                </div>
                            )
                        }


                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            returnRateStats.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ minHeight: 400, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            Return Rate
                                        </div>
                                        {
                                            groupedReturnRate && Object.keys(groupedReturnRate)?.length > 0 ? (
                                                <div >
                                                    <BarReturnRateChart groupedData={groupedReturnRate} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }


                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            aucStats.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ minHeight: 400, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            AUC
                                        </div>
                                        {
                                            groupedAUC && Object.keys(groupedAUC)?.length > 0 ? (
                                                <div >
                                                    <BarAucChart groupedData={groupedAUC} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }

                                    </div>
                                </div>
                            )
                        }


                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="gutter-row" >
                        {
                            actualPlanStats.loading ? (<Skeleton />) : (
                                <div className='dashboard-card-main' style={{ minHeight: 400, display: "flex", justifyContent: "center" }}>
                                    {/* <Calendar fullscreen={false} /> */}
                                    <div className='dashboard-card-content'>
                                        <div className='dashboard-card-hd text-center'>
                                            Plan vs Actual
                                        </div>
                                        {
                                            groupedPlanActual && Object.keys(groupedPlanActual)?.length > 0 ? (
                                                <div >
                                                    <BarPlanActualChart groupedData={groupedPlanActual} />
                                                </div>
                                            ) : (
                                                <div className='no_charts_data'>No Data</div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </Col>

                </Row>
            </div>

        </div>
    )
}

const Card = ({ title = "", trendValue, trendType, value = 0, }) => {
    return (
        <div className='dashboard-card-main'>
            <div className='dashboard-card-content'>
                <div className='dashboard-card-hd'>
                    {title}
                </div>
                <div className='dashboard-card-text'>
                    <div className='dashboard-card-text-left'>
                        {value}
                    </div>
                    <div className='dashboard-card-text-right'>
                        <div className='stats-icon'>{trendType === "up" ? <ArrowUpOutlined /> : <ArrowDownOutlined />}</div>
                        <div className='stats-value'>{trendValue}%</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

