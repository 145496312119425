import { useState, useRef, useCallback } from "react";
import { Select, Form, Table, Spin, Input, Button, DatePicker } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/images/logo.png";
import btnPrint from "../../assets/images/button_print.gif";
import csv from "../../assets/images/csv.png";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import { useDownloadExcel } from "react-export-table-to-excel";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import { getSaleOrderFormData } from "../../redux/actions/salesOrder.action";
import Loader from "react-spinners/BarLoader";
import { getCustomerBalances } from "../../redux/actions/customerBalances";
import { TabTitle } from "../../utils/GeneralFunction";
import moment from "moment";
import { getSpecialityByStyle } from "../../redux/actions/specialityByStyle";
import numeral from "numeral";
import { CSVLink } from "react-csv";
const { Option } = Select;
const SpecialtyByStyle = ({ token }) => {
  TabTitle("Customer-By-Style")
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const company_id = ReadCookie("defaultCompany");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    saleOrderFormData,
    OmsReprot,
    SpecialityByStyle
  } = useSelector((state) => state);
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [excelToogle, setExcelToogle] = useState(false);
  const [data, setData] = useState([])
  const [excelData, setExcelData] = useState([])

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const handlePrint = () => {
    window.print();
  }

  const handleChanges = (val) => {

    dispatch(getSaleOrderFormData(token, company_id, menuId, val, ""));
  };

  function debounce(cb, delay = 1000) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }

  const optimizedFn = useCallback(debounce(handleChanges), []);

  const onFinish = async (e) => {
    setSubmit(true);
    setLoading(true);
    await form.validateFields().then((values) => {
      dispatch(getSpecialityByStyle(token, values)).then((res) => {
        setData(res?.payload?.data)
        setLoading(false);
        downloadExcelFileData(res?.payload?.data);
      }
      );
    });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Division": v.gender_name,
          "Region": v.region_name,
          "Customer": v.customer_name,
          "Year": v.years,
          "Month": v.months,
          "Status": v.status,
          "PO NO": v.reference_no,
          "Tran Type": v.tran_type,
          "Tran No": v.tran_no,
          "SKU": v.sku,
          "Inseam": v.inseam,
          "Style": v.style_name,
          "Wash": v.wash_name,
          "Amount $": v.shipped_amount,
          "Unit": v.unit,
        };
      })
    );
  }

  const columns = [
    {
      title: "#",
      width: 40,
      // className: "b_bottom",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Division",
      dataIndex: "gender_name",
      key: "gender_name",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.gender_name !== null) {
          return record.gender_name.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.gender_name !== null & b.gender_name !== null) {
          return a.gender_name.localeCompare(b.gender_name)
        }
      },
    },
    {
      title: "Region",
      dataIndex: "region_name",
      key: "region_name",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.region_name !== null) {
          return record.region_name.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.region_name !== null & b.region_name !== null) {
          return a.region_name.localeCompare(b.region_name)
        }
      },
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.customer_name !== null) {
          return record.customer_name.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.customer_name !== null & b.customer_name !== null) {
          return a.customer_name.localeCompare(b.customer_name)
        }
      },
    },
    {
      title: "Year",
      dataIndex: "years",
      key: "years",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.years !== null) {
          return record.years.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        return a.years - b.years
      },
    },
    {
      title: "Month",
      dataIndex: "months",
      key: "months",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.months !== null) {
          return record.months.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.months !== null & b.months !== null) {
          return a.months.localeCompare(b.months)
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.status !== null) {
          return record.status.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.status !== null & b.status !== null) {
          return a.status.localeCompare(b.status)
        }
      },
    },
    {
      title: "PO NO",
      dataIndex: "reference_no",
      key: "reference_no",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.reference_no !== null) {
          return record.reference_no.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.reference_no !== null & b.reference_no !== null) {
          return a.reference_no.localeCompare(b.reference_no)
        }
      },
    },
    {
      title: "Tran Type",
      dataIndex: "tran_type",
      key: "tran_type",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.tran_type !== null) {
          return record.tran_type.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.tran_type !== null & b.tran_type !== null) {
          return a.tran_type.localeCompare(b.tran_type)
        }
      },
    },
    {
      title: "Tran No",
      dataIndex: "tran_no",
      key: "tran_no",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.tran_no !== null) {
          return record.tran_no.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        return a.tran_no - b.tran_no
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.sku !== null) {
          return record.sku.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        return a.sku - b.sku
      },
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.inseam !== null) {
          return record.inseam.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        return a.inseam - b.inseam
      },
    },
    {
      title: "Style",
      dataIndex: "style_name",
      key: "style_name",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.style_name !== null) {
          return record.style_name.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.style_name !== null & b.style_name !== null) {
          return a.style_name.localeCompare(b.style_name)
        }
      },
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      key: "wash_name",
      // className: " b_left b_bottom",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.wash_name !== null) {
          return record.wash_name.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        if (a.wash_name !== null & b.wash_name !== null) {
          return a.wash_name.localeCompare(b.wash_name)
        }
      },
    },
    {
      title: "Amount $",
      dataIndex: "shipped_amount",
      key: "shipped_amount",
      // className: " b_left b_bottom",
      render: (text, record) => {
        return numeral(record.shipped_amount).format("0")
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'

              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.shipped_amount !== null) {
          return record.shipped_amount.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        return a.shipped_amount - b.shipped_amount
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      // className: " b_left b_bottom",
      render: (text, record) => {
        return numeral(record.unit).format("0")
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              allowClear
              autoFocus
              className='pop-search-gen pop-220'
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            />
          </>
        );
      },
      filterIcon: filtered => filtered ? <FilterFilled /> : <  SearchOutlined />,
      onFilter: (value, record) => {
        if (record.unit !== null) {
          return record.unit.toString().toLowerCase().includes(value.toLowerCase())
        }
      },
      sorter: (a, b) => {
        return a.unit - b.unit
      },
    },
  ];


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="report-wrapper-main header_class">
              <div className="row justify-content-between pb-2">
                <div className="col-3 d-flex align-items-start  box-space">
                  <div className="logobrand">
                    <img id="ImgLogo" alt="logo" src={logo} />
                  </div>
                </div>
                <div className="col-sm-4 box-space right-content-header text-right pb-0">
                  <div className=" d-flex justify-content-end">
                    <div className=" d-flex justify-content-end printing_class mr-8">
                      <div className="print_icon printing_class">

                        {OmsReprot?.payload?.data.length ? (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            className="fa-solid fa-print printing_class"
                          ></i>
                        ) : (
                          <i
                            id="Image1"
                            title="Print"
                            alt="Print"
                            src={btnPrint}
                            className="fa-solid fa-print printing_class"
                            onClick={handlePrint}
                          ></i>
                        )}
                      </div>
                      <div className="print_icon printing_class" >
                        <CSVLink
                          filename={"Customer-By-Style.csv"}
                          data={excelData}
                          onClick={() => {
                          }}
                        >
                          <input
                            type="image"
                            name="ImgExcel"
                            alt="Export to excel"
                            id="ImgExcel"
                            title="Export to excel"
                            src={csv}
                            style={{ borderWidth: "0px", height: "14px" }}
                          />
                        </CSVLink>
                      </div>
                    </div>
                    <span style={{ fontWeight: "bolder" }}>
                      Customer By Style
                      <br />
                      Rpt # 006
                      <br />
                      Login as:
                      <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                        {" "}
                        {userName}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row printing_class">
                <div className="col text-sm-right pb-1">
                </div>
              </div>
              <>

                <div className="business_class_oms mb-2 distributor">
                  <Form
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                  >
                    <div className="main_form row">
                      <div className="main_form row">
                        <div className="col-12 col-md-4">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label className="labels width44" >From Month:</label>
                            <div className="w-100">
                              <Form.Item
                                className="full_date"
                                name="from_date"
                                initialValue={moment().startOf('year')}
                              >
                                <DatePicker
                                  size="small"
                                  defaultValue={moment().format("MM-YYYY")}
                                  className={"input-width"}
                                  picker="month"
                                  format={"MM-YYYY"}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label className="labels width44" >To Month:</label>
                            <div className="w-100">
                              <Form.Item
                                className="full_date"
                                name="to_date"
                                initialValue={moment().endOf('year')}
                              >
                                <DatePicker
                                  size="small"
                                  defaultValue={moment().format("MM-YYYY")}
                                  className={"input-width"}
                                  picker="month"
                                  format={"MM-YYYY"}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label htmlFor="" className="labels width30">
                              Customer:
                            </label>
                            <div className="w-100">
                              <Form.Item name="customer" initialValue={"All"}>
                                <Select
                                  notFoundContent={
                                    saleOrderFormData.loading ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Loader
                                          type="Oval"
                                          color="#3c4b58"
                                          height={20}
                                          width={20}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          textAlign: "center",
                                        }}
                                      >
                                        No Data
                                      </div>
                                    )
                                  }
                                  className={"select_Inputs"}
                                  autoComplete={"nope"}
                                  allowClear
                                  size="small"
                                  showSearch
                                  optionFilterProp="children"
                                  loading={saleOrderFormData.loading}

                                  defaultValue={form.getFieldValue().customer}
                                  placeholder="Select Customer"
                                  onSearch={(value) => {
                                    optimizedFn(value);
                                  }}
                                >
                                  <Option value="All">All</Option>
                                  {saleOrderFormData?.payload?.customerDetail.map(
                                    (item) => {
                                      return (
                                        <Option
                                          defaultValue={"All"}
                                          key={item.customer_id}
                                          value={item.customer_id}
                                        >
                                          {" "}
                                          {`${item.customer_code} - ${item.customer_name}`}
                                        </Option>
                                      );
                                    }
                                  )}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="main_form row form-margin">
                        <div className="col-12 col-md-4">
                          <div className=" d-flex flex-row align-items-baseline">
                            <label className="labels width44" >Status:</label>
                            <div className="w-100">
                              <Form.Item
                                className=" w-100"
                                name="status"
                                initialValue={"All"}
                              >
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  className="select_Inputs"
                                  size="small"
                                >
                                  <Option key={"All"} value={"All"}>
                                    All
                                  </Option>
                                  <Option key={"SHIP"} value={"SHIP"}>
                                    SHIP
                                  </Option>
                                  <Option key={"RETURN"} value={"RETURN"}>
                                    RETURN
                                  </Option>
                                  <Option key={"PENDING"} value={"PENDING"}>
                                    PENDING
                                  </Option>=
                                  <Option key={"POD"} value={"POD"}>
                                    POD
                                  </Option>
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className=" d-flex flex-row align-items-baseline" style={{ justifyContent: "start", marginTop: "3px" }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="submit-btn-btm m-0"
                            >
                              Generate
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>

              </>
            </div>
            <div >
              {/* <div className="col"> */}
              <div className="maxWidth mt-3">
                <>
                  {/* Report 1 */}
                  {loading ? (
                    <div className="maxWidth d-flex align-items-center justify-content-center spin-style mt-3">
                      <Spin />
                    </div>
                  ) : SpecialityByStyle?.payload?.data.length ? (
                    <Table
                      showSorterTooltip={false}
                      className="fix-scroll-table  table-header-sticky  border-line-th  bottom-align-th  table-td-border-right  th-general-style  
                                 td-general-style  even-tr-style border"
                      size="small"
                      pagination={{
                        defaultCurrent: 1,
                        defaultPageSize: 100,
                        pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000]
                      }}
                      bordered
                      columns={columns}
                      dataSource={
                        SpecialityByStyle?.payload?.data
                          ? SpecialityByStyle?.payload?.data
                          : []
                      }
                      scroll={{ x: "max-content" }}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: "#CCCCCC",
                        borderWidth: "1pt",
                        borderStyle: "Dashed",
                        fontWeight: "bold",
                        height: "25px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      No record(s)
                    </div>
                  )}
                </>
                {/* </div> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default SpecialtyByStyle;
