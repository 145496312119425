import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row as Brow, Col as Bcol } from "reactstrap";
import {
  Button,
  Form,
  Select,
  Table,
  Spin,
  DatePicker,
  Input,
  InputNumber,
} from "antd";
import { ReadCookie } from "../../utils/readCookie";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import moment from "moment";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import csv from "../../assets/images/csv.png";
import pdf from "../../assets/images/PDF_file_icon.svg.png";
import { getCommissionReport } from "../../redux/actions/commissionReport.action";
import { SearchOutlined, FilterFilled } from "@ant-design/icons";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { getOnlineOrdersSummary } from "../../redux/actions/bookSummary.action";
const { Option } = Select;

const OnlineOrdersSummary = () => {
  const [form] = Form.useForm();
  const [minimize, setMinimize] = useState(false);
  const [toogle, setToogle] = useState(false);
  const submitButton = useRef(null);
  const dispatch = useDispatch();
  const menuId = ReadCookie("menuId");
  const token = ReadCookie("token");
  const [dscoInvoiceList, setDscoInvoiceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const tableRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const companyId = ReadCookie("defaultCompany");
  const company_id = ReadCookie("defaultCompany");
  const { dscoInvoice, commissionReport } = useSelector((state) => state);
  const [excelData, setExcelData] = useState([]);

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return index + 1;
      },
      width: 40,
    },
    {
      title: "P.O #",
      dataIndex: "po_no",
      key: "po_no",
    },
    {
      title: "Sub Total",
      dataIndex: "sub_total",
      key: "sub_total",
      render: (text, record) => {
        return numeral(record.sub_total).format("0,0.00");
      },
      align: "right",
    },
    {
      title: "Shipping Cost",
      dataIndex: "shipping_cost",
      key: "shipping_cost",
      render: (text, record) => {
        return numeral(record.shipping_cost).format("0,0.00");
      },
      align: "right",
    },
    {
      title: "Tax Amount",
      dataIndex: "add_tax",
      key: "add_tax",
      align: "right",
    },
    {
      title: "Discount Code",
      dataIndex: "discount_code",
      key: "discount_code",
    },
    {
      title: "Discount Amount",
      dataIndex: "discount_amount",
      key: "discount_amount",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return numeral(record.total).format("0,0.00");
      },
      align: "right",
    },
    {
      title: "Ship Via",
      dataIndex: "ship_via_name",
      key: "ship_via_name",
    },
    {
      title: "Wash Style",
      dataIndex: "wash_style_name",
      key: "wash_style_name",
    },
    {
      title: "SKU Inseam Size",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Net Amount",
      dataIndex: "net_amount",
      key: "net_amount",
      render: (text, record) => {
        return numeral(record.net_amount).format("0,0.00");
      },
      align: "right",
    },
    {
      title: "First Last Name",
      dataIndex: "bill_addressee",
      key: "bill_addressee",
    },
    {
      title: "Address 1",
      dataIndex: "bill_address_1",
      key: "bill_address_1",
    },
    {
      title: "Address 2",
      dataIndex: "bill_address_2",
      key: "bill_address_2",
    },
    {
      title: "City",
      dataIndex: "bill_city_name",
      key: "bill_city_name",
    },
    {
      title: "Zip Code",
      dataIndex: "bill_zip_code",
      key: "bill_zip_code",
    },
    {
      title: "State",
      dataIndex: "bill_state_name",
      key: "bill_state_name",
    },
    {
      title: "Country",
      dataIndex: "bill_country_name",
      key: "bill_country_name",
    },
    {
      title: "First Last Name",
      dataIndex: "ship_addressee",
      key: "ship_addressee",
    },
    {
      title: "Address 1",
      dataIndex: "ship_address_1",
      key: "ship_address_1",
    },
    {
      title: "Address 2",
      dataIndex: "ship_address_2",
      key: "ship_address_2",
    },
    {
      title: "City",
      dataIndex: "ship_city_name",
      key: "ship_city_name",
    },
    {
      title: "Zip Code",
      dataIndex: "ship_zip_code",
      key: "ship_zip_code",
    },
    {
      title: "State",
      dataIndex: "ship_state_name",
      key: "ship_state_name",
    },
    {
      title: "Country",
      dataIndex: "ship_country_name",
      key: "ship_country_name",
    },
    {
      title: "BackOrder",
      dataIndex: "quantity_backorder",
      key: "quantity_backorder",
      align: "right",
    },
    {
      title: "Commited",
      dataIndex: "quantity_committed",
      key: "quantity_committed",
      align: "right",
    },
    {
      title: "Packed",
      dataIndex: "quantity_packed",
      key: "quantity_packed",
      align: "right",
    },
    {
      title: "Available",
      // dataIndex: "un_applied",
      // key: "un_applied",
      align: "right",
    },
  ];

  const onFinish = async () => {
    setLoading(true);
    await form
      .validateFields()
      .then((values) => {
        dispatch(getOnlineOrdersSummary(token, values)).then((res) => {
          setToogle(true);
          setLoading(false);
          setDscoInvoiceList(res?.payload?.data);
          downloadExcelFileData(res?.payload?.data);
        });
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "P.O #": v.po_no,
          "Sub Total": numeral(v.sub_total).format("0,0.00"),
          "Shipping Cost": numeral(v.shipping_cost).format("0,0.00"),
          "Tax Amount": v.add_tax,
          "Discount Code": v.discount_code,
          "Discount Amount": v.discount_amount,
          "Total": numeral(v.total).format("0,0.00"),
          "Ship Via": v.ship_via_name,
          "Wash Style": v.wash_style_name,
          "SKU Inseam Size": v.sku,
          "Net Amount": numeral(v.net_amount).format("0,0.00"),
          "First Last Name": v.bill_addressee,
          "Address 1": v.bill_address_1,
          "Address 2": v.bill_address_2,
          "City": v.bill_city_name,
          "Zip Code": v.bill_zip_code,
          "State": v.bill_state_name,
          "Country": v.bill_country_name,
          "First Last Name": v.ship_addressee,
          "Address 1": v.ship_address_1,
          "Address 2": v.ship_address_2,
          "City": v.ship_city_name,
          "Zip Code": v.ship_zip_code,
          "State": v.ship_state_name,
          "Country": v.ship_country_name,
          "BackOrder": v.quantity_backorder,
          "Commited": v.quantity_committed,
          "Packed": v.quantity_packed,
          "Available": v.ship_country_name,
        };
      })
    );
  }

  // const exportToExcel = async () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Sheet 1");

  //   worksheet.columns = [
  //     { header: "Payment #", key: "payment_header_id", width: 13 },
  //     { header: "SalePerson Name", key: "salesperson_name", width: 30 },
  //     { header: "Voucher No", key: "voucher_no", width: 15 },
  //     { header: "Payment Date", key: "payment_date", width: 15 },
  //     { header: "Invoice #", key: "invoice_no", width: 15 },
  //     { header: "Invoice Date", key: "invoice_date", width: 15 },
  //     { header: "Order #", key: "order_no", width: 15 },
  //     { header: "Order Date", key: "order_date", width: 15 },
  //     { header: "PO", key: "po_no", width: 20 },
  //     { header: "Customer", key: "customer_name", width: 35 },
  //     { header: "Region", key: "region_name", width: 25 },
  //     { header: "Amount Paid", key: "amount_paid", width: 15 },
  //     { header: "Shipping Cost", key: "shipping_cost", width: 15 },
  //     { header: "Net Amount", key: "net_amount", width: 15 },
  //     { header: "Commission", key: "commission", width: 15 },
  //     { header: "Payment Type", key: "payment_type_name", width: 20 },
  //     { header: "Gender", key: "gender_name", width: 15 },
  //     { header: "UnApplied", key: "un_applied", width: 15 },
  //   ];

  //   let totalAmount = 0;
  //   let totalCommission = 0;

  //   commissionReport?.payload?.data.forEach((item) => {
  //     worksheet.addRow({
  //       payment_header_id: item.payment_header_id,
  //       salesperson_name: item.salesperson_name,
  //       voucher_no: item.voucher_no,
  //       payment_date: item.payment_date,
  //       invoice_no: item.invoice_no,
  //       invoice_date: item.invoice_date,
  //       order_no: item.order_no,
  //       order_date: item.order_date,
  //       po_no: item.po_no,
  //       customer_name: item.customer_name,
  //       region_name: item.region_name,
  //       amount_paid: `$ ${numeral(item.amount_paid).format("0,00.00")}`,
  //       shipping_cost: `$ ${numeral(item.shipping_cost).format("0,00.00")}`,
  //       net_amount: `$ ${numeral(item.net_amount).format("0,00.00")}`,
  //       commission: `$ ${numeral(item.commission).format("0,00.00")}`,
  //       payment_type_name: item.payment_type_name,
  //       gender_name: item.gender_name,
  //       un_applied: `$ ${numeral(item.un_applied).format("0,00.00")}`,
  //     });
  //     totalAmount += Number(item.amount_paid);
  //     totalCommission += Number(item.commission);
  //   });
  //   worksheet.addRow({
  //     amount_paid: numeral(totalAmount).format("0,00.00"),
  //     commission: numeral(totalCommission).format("0,00.00"),
  //   });

  //   try {
  //     const buffer = await workbook.xlsx.writeBuffer();
  //     const blob = new Blob([buffer], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });

  //     const filename = "Commission-Report.xlsx";

  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = filename;
  //     link.click();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // function generatePDF() {
  //   var doc = new jsPDF();

  //   var columns = [
  //     "Payment #",
  //     "SalesPerson",
  //     "Voucher No",
  //     "Payment Date",
  //     "Invoice #",
  //     "Invoice Date",
  //     "Order #",
  //     "Order Date",
  //     "PO",
  //     "Customer",
  //     "Region",
  //     "Amount Paid",
  //     "Shipping Cost",
  //     "Net Amount",
  //     "Commission",
  //     "Payment Type",
  //     "Gender",
  //     "UnApplied",
  //   ];
  //   var rows = [];

  //   commissionReport?.payload?.data.forEach((item) => {
  //     var row = [
  //       item.payment_header_id,
  //       item.salesperson_name,
  //       item.voucher_no,
  //       moment(item.payment_date).format("MM-DD-YYYY"),
  //       item.invoice_no,
  //       moment(item.invoice_date).format("MM-DD-YYYY"),
  //       item.order_no,
  //       moment(item.order_date).format("MM-DD-YYYY"),
  //       item.po_no,
  //       item.customer_name,
  //       item.region_name,
  //       "$" + numeral(item.amount_paid).format("0,00.00"),
  //       "$" + numeral(item.shipping_cost).format("0,00.00"),
  //       "$" + numeral(item.net_amount).format("0,00.00"),
  //       "$" + numeral(item.commission).format("0,00.00"),
  //       item.payment_type_name,
  //       item.gender_name,
  //       "$" + numeral(item.un_applied).format("0,00.00"),
  //     ];
  //     rows.push(row);
  //   });

  //   const totalAmount = commissionReport?.payload?.data.reduce(
  //     (acc, item, index) => acc + parseFloat(item.amount_paid),
  //     0
  //   );
  //   const totalCommission = commissionReport?.payload?.data.reduce(
  //     (acc, item, index) => acc + (parseFloat(item.commission) || 0),
  //     0
  //   );

  //   doc.autoTable({
  //     head: [columns],
  //     body: rows,
  //     styles: { fontSize: 4 },
  //   });

  //   const startY = doc.lastAutoTable.finalY || 10;
  //   const footerRow = [
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     "Total:",
  //     "$" + numeral(totalAmount).format("0,00.00"),
  //     " ",
  //     " ",
  //     " ",
  //     "$" + numeral(totalCommission).format("0,00.00"),
  //     " ",
  //     " ",
  //     " ",
  //     " ",
  //     " ",
  //   ];
  //   doc.autoTable({
  //     body: [footerRow],
  //     startY: startY + 2,
  //     styles: { fontSize: 4 },
  //   });

  //   doc.save("Commission-Report.pdf");
  // }

  const totalQuantity = () => {
    let sumQty = dscoInvoiceList.reduce(function (previousValue, currentValue) {
      return (previousValue += Number(currentValue.total_qty));
    }, 0);
    return sumQty;
  };

  const totalSum = () => {
    let sumAmount = dscoInvoiceList.reduce(function (
      previousValue,
      currentValue
    ) {
      return (previousValue += Number(currentValue.cost_price));
    },
    0);
    return sumAmount;
  };

  return (
    <div className="styleContainer">
      <div className="styleBody h-100">
        <PageHeader
          title={"Online Orders Summary"}
          minimize={setMinimize}
          check={minimize}
        />
        {!minimize && (
          <div className="bodyStyle">
            <div className="formProduct p-0">
              <Form onFinish={onFinish} form={form} name="control-hooks">
                <div className="container-fluid pbpx-5 ptpx-5">
                  <Brow>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div className="relative">
                          <Form.Item
                            name="from_date"
                            label="From Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().startOf("year")}
                          >
                            <DatePicker
                              size="small"
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div>
                        <div className="relative">
                          <Form.Item
                            name="to_date"
                            label="To Date:"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValue={moment().endOf("year")}
                          >
                            <DatePicker
                              size="small"
                              className="styleInput w-100"
                              format={"MM-DD-YYYY"}
                              defaultValue={moment().format("MM-DD-YYYY")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div>
                          <Form.Item
                            name="order_no"
                            label="Order #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                    <Bcol lg={6} xl={4} xxl={3}>
                      <div className="">
                        <div>
                          <Form.Item
                            name="po_no"
                            label="PO #"
                            labelAlign="left"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input size="small" className="styleInput" />
                          </Form.Item>
                        </div>
                      </div>
                    </Bcol>
                  </Brow>
                </div>
              </Form>
              <Button
                htmlType="submit"
                type="primary"
                className="submit-btn-btm "
                onClick={onFinish}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {loading ? (
          <div className=" report-wrapper-main d-flex align-items-center justify-content-center spin-style mt-3">
            <Spin size="large" />
          </div>
        ) : dscoInvoiceList.length > 0 ? (
          <Table
            title={() => (
              <>
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <div className="print_icon printing_class">
                    <CSVLink
                      filename={"Online-Orders-Summary.csv"}
                      data={excelData}
                      onClick={() => {}}
                    >
                      <input
                        type="image"
                        name="ImgCSV"
                        alt="Export to CSV"
                        id="ImgCSV"
                        title="Export to CSV"
                        src={csv}
                        style={{
                          borderWidth: "0px",
                          height: "24px",
                          float: "inline-end",
                          margin: "12px",
                        }}
                      />
                    </CSVLink>
                  </div>
                  {/* <div className="print_icon printing_class">
                    <input
                      type="image"
                      name="ImgExcel"
                      alt="Export to Excel"
                      id="ImgExcel"
                      title="Export to Excel"
                      src={xlsx}
                      onClick={exportToExcel}
                      style={{
                        borderWidth: "0px",
                        height: "24px",
                        float: "inline-end",
                        margin: "12px",
                      }}
                    />
                  </div>
                  <div className="print_icon printing_class">
                    <input
                      type="image"
                      name="ImgPDF"
                      alt="Export to PDF"
                      id="ImgPDF"
                      title="Export to PDF"
                      src={pdf}
                      onClick={generatePDF}
                      style={{
                        borderWidth: "0px",
                        height: "24px",
                        float: "inline-end",
                        margin: "12px",
                      }}
                    />
                  </div> */}
                </div>
              </>
            )}
            ref={tableRef}
            size="small"
            bordered={false}
            type="company"
            perPage={15}
            scroll={{
              x: "max-content",
            }}
            dataSource={dscoInvoiceList}
            columns={columns}
            loading={commissionReport.loading}
            pagination={{
              defaultCurrent: 1,
              defaultPageSize: 100,
              pageSizeOptions: [100, 500, 1000, 2000, 2500, 3000],
            }}
          />
        ) : (
          <div
            style={{
              backgroundColor: "#CCCCCC",
              borderWidth: "1pt",
              borderStyle: "Dashed",
              fontWeight: "bold",
              height: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No record(s)
          </div>
        )}
      </div>
    </div>
  );
};

export default OnlineOrdersSummary;
