import React, { useEffect, useState } from "react";
import { Spin, Select, Form, Button, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ReadCookie } from "../../utils/readCookie";
import btnPrint from "../../assets/images/button_print.gif";
import xlsx from "../../assets/images/ico_xls_sm.gif";
import logo from "../../assets/images/logo.png";
import "../Distributor/Distributor.css";
import { useRef } from "react";
import {
  locationWiseReport,
  NegativeInventorylocationWiseReport,
} from "../../redux/actions/LocationWise.action";
import numeral from "numeral";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
const { Option } = Select;

const LocationWise = ({ token }) => {
  TabTitle("LocationWise");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { adminLocation, locationWise, negativeInventorylocationWise } =
    useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [locationWiseList, setLocationWiseList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [locationWiseList2, setLocationWiseList2] = useState([]);

  const onFinish = async (e) => {
    setLoading(true);
    await form.validateFields().then((data) => {
      dispatch(locationWiseReport(token, data)).then((res) => {
        setLoading(false);
        setLocationWiseList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
        setLocationWiseList2([])
      });
    });
  };

  const onFinish2 = async (e) => {
    setLoading(true);
    await form.validateFields().then((data) => {
      dispatch(NegativeInventorylocationWiseReport(token, data)).then((res) => {
        setLoading(false);
        setLocationWiseList2(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
        setLocationWiseList([]);
      });
    });
  };


  useEffect(() => {
    dispatch(getAdminLocation(token, menuId));
  }, [dispatch, menuId, token]);

  const handlePrint = () => {
    window.print();
  };

  const colums = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      // width: 80,
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
      // width: 50,
    },
    {
      title: "Style",
      dataIndex: "style_name",
      key: "style_name",
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      key: "wash_name",
    },
    {
      title: "Location name",
      dataIndex: "location_name",
      key: "location_name",
      // width: 60,
    },
    {
      children: [
        {
          title: "T-Jacket",
          dataIndex: "stage",
          key: "stage",
          width: 60,
          children: [
            {
              title: "Shirt",
              dataIndex: "stage",
              key: "stage",
              width: 60,
              children: [
                {
                  title: "W-Plus",
                  dataIndex: "stage",
                  key: "stage",
                  width: 60,
                  children: [
                    {
                      title: "Kids",
                      dataIndex: "stage",
                      key: "stage",
                      width: 60,
                      children: [
                        {
                          title: "Men",
                          dataIndex: "stage",
                          key: "stage",
                          width: 60,
                          children: [
                            {
                              title: "Women",
                              dataIndex: "stage",
                              key: "stage",
                              width: 60,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: '"2/3"',
          align: "right",
          dataIndex: "qty_1",
          key: "qty_1",
          width: 55,
          children: [
            {
              title: "XS",
              dataIndex: "qty_1",
              key: "qty_1",
              width: 50,
              align: "right",
              children: [
                {
                  title: "10W",
                  dataIndex: "qty_1",
                  key: "qty_1",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "2",
                      dataIndex: "qty_1",
                      key: "qty_1",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "27",
                          dataIndex: "qty_1",
                          key: "qty_1",
                          align: "right",
                          width: 50,
                          children: [
                            {
                              title: "23",
                              dataIndex: "qty_1",
                              key: "qty_1",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: '"4/5"',
          align: "right",
          dataIndex: "qty_2",
          key: "qty_2",
          width: 50,
          children: [
            {
              title: "S",
              align: "right",
              dataIndex: "qty_2",
              key: "qty_2",
              width: 55,
              children: [
                {
                  title: "12W",
                  align: "right",
                  dataIndex: "qty_2",
                  key: "qty_2",
                  width: 55,
                  children: [
                    {
                      title: "3",
                      align: "right",
                      dataIndex: "qty_2",
                      key: "qty_2",
                      width: 50,
                      children: [
                        {
                          title: "28",
                          align: "right",
                          dataIndex: "qty_2",
                          key: "qty_2",
                          width: 50,
                          children: [
                            {
                              title: "24",
                              align: "right",
                              dataIndex: "qty_2",
                              key: "qty_2",
                              width: 40,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: '"6/6X"',
          dataIndex: "qty_3",
          key: "qty_3",
          align: "right",
          width: 60,
          children: [
            {
              title: "M",
              dataIndex: "qty_3",
              key: "qty_3",
              width: 50,
              align: "right",
              children: [
                {
                  title: "14W",
                  dataIndex: "qty_3",
                  key: "qty_3",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "4",
                      dataIndex: "qty_3",
                      key: "qty_3",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "29",
                          dataIndex: "qty_3",
                          key: "qty_3",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "25",
                              dataIndex: "qty_3",
                              key: "qty_3",
                              width: 45,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_4",
          key: "qty_4",
          width: 50,
          align: "right",
          children: [
            {
              title: "L",
              dataIndex: "qty_4",
              key: "qty_4",
              width: 50,
              align: "right",
              children: [
                {
                  title: "16W",
                  dataIndex: "qty_4",
                  key: "qty_4",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "5",
                      dataIndex: "qty_4",
                      key: "qty_4",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "30",
                          dataIndex: "qty_4",
                          key: "qty_4",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "26",
                              dataIndex: "qty_4",
                              key: "qty_4",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_5",
          key: "qty_5",
          width: 50,
          align: "right",
          children: [
            {
              title: "XL",
              dataIndex: "qty_5",
              key: "qty_5",
              width: 50,
              align: "right",
              children: [
                {
                  title: "18W",
                  dataIndex: "qty_5",
                  key: "qty_5",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "6",
                      dataIndex: "qty_5",
                      key: "qty_5",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "31",
                          dataIndex: "qty_5",
                          key: "qty_5",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "27",
                              dataIndex: "qty_5",
                              key: "qty_5",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_6",
          key: "qty_6",
          width: 50,
          align: "right",

          children: [
            {
              title: "1XL",
              dataIndex: "qty_6",
              key: "qty_6",
              width: 50,
              align: "right",
              children: [
                {
                  title: "20W",
                  dataIndex: "qty_6",
                  key: "qty_6",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "6x",
                      dataIndex: "qty_6",
                      key: "qty_6",
                      width: 50,
                      align: "right",

                      children: [
                        {
                          title: "32",
                          dataIndex: "qty_6",
                          key: "qty_6",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "28",
                              dataIndex: "qty_6",
                              key: "qty_6",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_7",
          key: "qty_7",
          width: 50,
          align: "right",

          children: [
            {
              title: "2XL",
              dataIndex: "qty_7",
              key: "qty_7",
              width: 50,
              align: "right",
              children: [
                {
                  title: "22W",
                  dataIndex: "qty_7",
                  key: "qty_7",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "7",
                      dataIndex: "qty_7",
                      key: "qty_7",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "33",
                          dataIndex: "qty_7",
                          key: "qty_7",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "29",
                              dataIndex: "qty_7",
                              key: "qty_7",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_8",
          key: "qty_8",
          width: 50,
          align: "right",

          children: [
            {
              title: "3XL",
              dataIndex: "qty_8",
              key: "qty_8",
              width: 50,
              align: "right",
              children: [
                {
                  title: "24W",
                  dataIndex: "qty_8",
                  key: "qty_8",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "7x",
                      dataIndex: "qty_8",
                      key: "qty_8",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "34",
                          dataIndex: "qty_8",
                          key: "qty_8",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "30",
                              dataIndex: "qty_8",
                              key: "qty_8",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_9",
          key: "qty_9",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_9",
              key: "qty_9",
              width: 50,
              align: "right",
              children: [
                {
                  title: "26W",
                  dataIndex: "qty_9",
                  key: "qty_9",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "8",
                      dataIndex: "qty_9",
                      key: "qty_9",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "35",
                          dataIndex: "qty_9",
                          key: "qty_9",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "31",
                              dataIndex: "qty_9",
                              key: "qty_9",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_10",
          key: "qty_10",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_10",
              key: "qty_10",
              width: 50,
              align: "right",
              children: [
                {
                  title: "28W",
                  dataIndex: "qty_10",
                  key: "qty_10",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "10",
                      dataIndex: "qty_10",
                      key: "qty_10",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "36",
                          dataIndex: "qty_10",
                          key: "qty_10",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "32",
                              dataIndex: "qty_10",
                              key: "qty_10",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_11",
          key: "qty_11",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_11",
              key: "qty_11",
              width: 50,
              align: "right",
              children: [
                {
                  title: "30W",
                  dataIndex: "qty_11",
                  key: "qty_11",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "12",
                      dataIndex: "qty_11",
                      key: "qty_11",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "38",
                          dataIndex: "qty_11",
                          key: "qty_11",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "33",
                              dataIndex: "qty_11",
                              key: "qty_11",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_12",
          key: "qty_12",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_12",
              key: "qty_12",
              width: 50,
              align: "right",
              children: [
                {
                  title: "32W",
                  dataIndex: "qty_12",
                  key: "qty_12",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "14",
                      dataIndex: "qty_12",
                      key: "qty_12",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "40",
                          dataIndex: "qty_12",
                          key: "qty_12",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "34",
                              dataIndex: "qty_12",
                              key: "qty_12",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_13",
          key: "qty_13",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_13",
              key: "qty_13",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_13",
                  key: "qty_13",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "16",
                      dataIndex: "qty_13",
                      key: "qty_13",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "42",
                          dataIndex: "qty_13",
                          key: "qty_13",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "35",
                              dataIndex: "qty_13",
                              key: "qty_13",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_14",
          key: "qty_14",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_14",
              key: "qty_14",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_14",
                  key: "qty_14",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "18",
                      dataIndex: "qty_14",
                      key: "qty_14",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "44",
                          dataIndex: "qty_14",
                          key: "qty_14",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "36",
                              dataIndex: "qty_14",
                              key: "qty_14",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_15",
          key: "qty_15",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_15",
              key: "qty_15",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_15",
                  key: "qty_15",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "20",
                      dataIndex: "qty_15",
                      key: "qty_15",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "46",
                          dataIndex: "qty_15",
                          key: "qty_15",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "37",
                              dataIndex: "qty_15",
                              key: "qty_15",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_16",
          key: "qty_16",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_16",
              key: "qty_16",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_16",
                  key: "qty_16",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "-",
                      dataIndex: "qty_16",
                      key: "qty_16",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "48",
                          dataIndex: "qty_16",
                          key: "qty_16",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "38",
                              dataIndex: "qty_16",
                              key: "qty_16",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Qty",
      dataIndex: "total_qty",
      key: "total_qty",
      align: "right",
    },
    {
      title: "Avg Rate",
      dataIndex: "avg_rate",
      key: "avg_rate",
      align: "right",
      // width: 35,
      render: (text, record) => {
        return <>$ {record.avg_rate}</>;
      },
    },
    {
      title: "Avg Amount",
      dataIndex: "avg_amount",
      key: "avg_amount",
      align: "right",
      // width: 35,
      render: (text, record) => {
        return <>$ {record.avg_amount}</>;
      },
    },
  ];
  const colums1 = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      // width: 80,
    },
    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
      // width: 50,
    },
    {
      title: "Style",
      dataIndex: "style_name",
      key: "style_name",
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      key: "wash_name",
    },
    {
      title: "Location name",
      dataIndex: "location_name",
      key: "location_name",
      // width: 60,
    },
    {
      children: [
        {
          title: "T-Jacket",
          dataIndex: "stage",
          key: "stage",
          width: 60,
          children: [
            {
              title: "Shirt",
              dataIndex: "stage",
              key: "stage",
              width: 60,
              children: [
                {
                  title: "W-Plus",
                  dataIndex: "stage",
                  key: "stage",
                  width: 60,
                  children: [
                    {
                      title: "Kids",
                      dataIndex: "stage",
                      key: "stage",
                      width: 60,
                      children: [
                        {
                          title: "Men",
                          dataIndex: "stage",
                          key: "stage",
                          width: 60,
                          children: [
                            {
                              title: "Women",
                              dataIndex: "stage",
                              key: "stage",
                              width: 60,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: '"2/3"',
          align: "right",
          dataIndex: "qty_1",
          key: "qty_1",
          width: 55,
          children: [
            {
              title: "XS",
              dataIndex: "qty_1",
              key: "qty_1",
              width: 50,
              align: "right",
              children: [
                {
                  title: "10W",
                  dataIndex: "qty_1",
                  key: "qty_1",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "2",
                      dataIndex: "qty_1",
                      key: "qty_1",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "27",
                          dataIndex: "qty_1",
                          key: "qty_1",
                          align: "right",
                          width: 50,
                          children: [
                            {
                              title: "23",
                              dataIndex: "qty_1",
                              key: "qty_1",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: '"4/5"',
          align: "right",
          dataIndex: "qty_2",
          key: "qty_2",
          width: 50,
          children: [
            {
              title: "S",
              align: "right",
              dataIndex: "qty_2",
              key: "qty_2",
              width: 55,
              children: [
                {
                  title: "12W",
                  align: "right",
                  dataIndex: "qty_2",
                  key: "qty_2",
                  width: 55,
                  children: [
                    {
                      title: "3",
                      align: "right",
                      dataIndex: "qty_2",
                      key: "qty_2",
                      width: 50,
                      children: [
                        {
                          title: "28",
                          align: "right",
                          dataIndex: "qty_2",
                          key: "qty_2",
                          width: 50,
                          children: [
                            {
                              title: "24",
                              align: "right",
                              dataIndex: "qty_2",
                              key: "qty_2",
                              width: 40,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: '"6/6X"',
          dataIndex: "qty_3",
          key: "qty_3",
          align: "right",
          width: 60,
          children: [
            {
              title: "M",
              dataIndex: "qty_3",
              key: "qty_3",
              width: 50,
              align: "right",
              children: [
                {
                  title: "14W",
                  dataIndex: "qty_3",
                  key: "qty_3",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "4",
                      dataIndex: "qty_3",
                      key: "qty_3",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "29",
                          dataIndex: "qty_3",
                          key: "qty_3",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "25",
                              dataIndex: "qty_3",
                              key: "qty_3",
                              width: 45,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_4",
          key: "qty_4",
          width: 50,
          align: "right",
          children: [
            {
              title: "L",
              dataIndex: "qty_4",
              key: "qty_4",
              width: 50,
              align: "right",
              children: [
                {
                  title: "16W",
                  dataIndex: "qty_4",
                  key: "qty_4",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "5",
                      dataIndex: "qty_4",
                      key: "qty_4",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "30",
                          dataIndex: "qty_4",
                          key: "qty_4",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "26",
                              dataIndex: "qty_4",
                              key: "qty_4",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_5",
          key: "qty_5",
          width: 50,
          align: "right",
          children: [
            {
              title: "XL",
              dataIndex: "qty_5",
              key: "qty_5",
              width: 50,
              align: "right",
              children: [
                {
                  title: "18W",
                  dataIndex: "qty_5",
                  key: "qty_5",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "6",
                      dataIndex: "qty_5",
                      key: "qty_5",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "31",
                          dataIndex: "qty_5",
                          key: "qty_5",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "27",
                              dataIndex: "qty_5",
                              key: "qty_5",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_6",
          key: "qty_6",
          width: 50,
          align: "right",

          children: [
            {
              title: "1XL",
              dataIndex: "qty_6",
              key: "qty_6",
              width: 50,
              align: "right",
              children: [
                {
                  title: "20W",
                  dataIndex: "qty_6",
                  key: "qty_6",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "6x",
                      dataIndex: "qty_6",
                      key: "qty_6",
                      width: 50,
                      align: "right",

                      children: [
                        {
                          title: "32",
                          dataIndex: "qty_6",
                          key: "qty_6",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "28",
                              dataIndex: "qty_6",
                              key: "qty_6",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_7",
          key: "qty_7",
          width: 50,
          align: "right",

          children: [
            {
              title: "2XL",
              dataIndex: "qty_7",
              key: "qty_7",
              width: 50,
              align: "right",
              children: [
                {
                  title: "22W",
                  dataIndex: "qty_7",
                  key: "qty_7",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "7",
                      dataIndex: "qty_7",
                      key: "qty_7",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "33",
                          dataIndex: "qty_7",
                          key: "qty_7",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "29",
                              dataIndex: "qty_7",
                              key: "qty_7",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_8",
          key: "qty_8",
          width: 50,
          align: "right",

          children: [
            {
              title: "3XL",
              dataIndex: "qty_8",
              key: "qty_8",
              width: 50,
              align: "right",
              children: [
                {
                  title: "24W",
                  dataIndex: "qty_8",
                  key: "qty_8",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "7x",
                      dataIndex: "qty_8",
                      key: "qty_8",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "34",
                          dataIndex: "qty_8",
                          key: "qty_8",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "30",
                              dataIndex: "qty_8",
                              key: "qty_8",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_9",
          key: "qty_9",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_9",
              key: "qty_9",
              width: 50,
              align: "right",
              children: [
                {
                  title: "26W",
                  dataIndex: "qty_9",
                  key: "qty_9",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "8",
                      dataIndex: "qty_9",
                      key: "qty_9",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "35",
                          dataIndex: "qty_9",
                          key: "qty_9",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "31",
                              dataIndex: "qty_9",
                              key: "qty_9",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_10",
          key: "qty_10",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_10",
              key: "qty_10",
              width: 50,
              align: "right",
              children: [
                {
                  title: "28W",
                  dataIndex: "qty_10",
                  key: "qty_10",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "10",
                      dataIndex: "qty_10",
                      key: "qty_10",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "36",
                          dataIndex: "qty_10",
                          key: "qty_10",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "32",
                              dataIndex: "qty_10",
                              key: "qty_10",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_11",
          key: "qty_11",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_11",
              key: "qty_11",
              width: 50,
              align: "right",
              children: [
                {
                  title: "30W",
                  dataIndex: "qty_11",
                  key: "qty_11",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "12",
                      dataIndex: "qty_11",
                      key: "qty_11",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "38",
                          dataIndex: "qty_11",
                          key: "qty_11",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "33",
                              dataIndex: "qty_11",
                              key: "qty_11",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_12",
          key: "qty_12",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_12",
              key: "qty_12",
              width: 50,
              align: "right",
              children: [
                {
                  title: "32W",
                  dataIndex: "qty_12",
                  key: "qty_12",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "14",
                      dataIndex: "qty_12",
                      key: "qty_12",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "40",
                          dataIndex: "qty_12",
                          key: "qty_12",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "34",
                              dataIndex: "qty_12",
                              key: "qty_12",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_13",
          key: "qty_13",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_13",
              key: "qty_13",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_13",
                  key: "qty_13",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "16",
                      dataIndex: "qty_13",
                      key: "qty_13",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "42",
                          dataIndex: "qty_13",
                          key: "qty_13",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "35",
                              dataIndex: "qty_13",
                              key: "qty_13",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_14",
          key: "qty_14",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_14",
              key: "qty_14",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_14",
                  key: "qty_14",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "18",
                      dataIndex: "qty_14",
                      key: "qty_14",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "44",
                          dataIndex: "qty_14",
                          key: "qty_14",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "36",
                              dataIndex: "qty_14",
                              key: "qty_14",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_15",
          key: "qty_15",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_15",
              key: "qty_15",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_15",
                  key: "qty_15",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "20",
                      dataIndex: "qty_15",
                      key: "qty_15",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "46",
                          dataIndex: "qty_15",
                          key: "qty_15",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "37",
                              dataIndex: "qty_15",
                              key: "qty_15",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          title: "-",
          dataIndex: "qty_16",
          key: "qty_16",
          width: 50,
          align: "right",

          children: [
            {
              title: "-",
              dataIndex: "qty_16",
              key: "qty_16",
              width: 50,
              align: "right",
              children: [
                {
                  title: "-",
                  dataIndex: "qty_16",
                  key: "qty_16",
                  width: 50,
                  align: "right",
                  children: [
                    {
                      title: "-",
                      dataIndex: "qty_16",
                      key: "qty_16",
                      width: 50,
                      align: "right",
                      children: [
                        {
                          title: "48",
                          dataIndex: "qty_16",
                          key: "qty_16",
                          width: 50,
                          align: "right",
                          children: [
                            {
                              title: "38",
                              dataIndex: "qty_16",
                              key: "qty_16",
                              width: 40,
                              align: "right",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "Qty",
      dataIndex: "total_qty",
      key: "total_qty",
      align: "right",
    },
    {
      title: "Avg Rate",
      dataIndex: "avg_rate",
      key: "avg_rate",
      align: "right",
      // width: 35,
      render: (text, record) => {
        return <>$ {record.avg_rate}</>;
      },
    },
    {
      title: "Avg Amount",
      dataIndex: "avg_amount",
      key: "avg_amount",
      align: "right",
      // width: 35,
      render: (text, record) => {
        return <>$ {record.avg_amount}</>;
      },
    },
  ];

  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          SKU: v.sku,
          Inseam: v.inseam,
          Style: v.style_name,
          Wash: v.wash_name,
          "Location name": v.location_name,
          '=""2/3""&CHAR(10)&""XS""&CHAR(10)&""2""&CHAR(10)&""27""&CHAR(10)&""23""':
            v.qty_1,
          '=""4/5""&CHAR(10)&""S""&CHAR(10)&""3""&CHAR(10)&""28""&CHAR(10)&""24""':
            v.qty_2,
          '=""6/6X""&CHAR(10)&""M""&CHAR(10)&""4""&CHAR(10)&""29""&CHAR(10)&""25""':
            v.qty_3,
          '=""-""&CHAR(10)&""L""&CHAR(10)&""5""&CHAR(10)&""30""&CHAR(10)&""26""':
            v.qty_4,
          '=""-""&CHAR(10)&""XL""&CHAR(10)&""6""&CHAR(10)&""31""&CHAR(10)&""27""':
            v.qty_5,
          '=""-""&CHAR(10)&""1XL""&CHAR(10)&""6x""&CHAR(10)&""32""&CHAR(10)&""28""':
            v.qty_6,
          '=""-""&CHAR(10)&""2XL""&CHAR(10)&""7""&CHAR(10)&""33""&CHAR(10)&""29""':
            v.qty_7,
          '=""-""&CHAR(10)&""3XL""&CHAR(10)&""7x""&CHAR(10)&""34""&CHAR(10)&""30""':
            v.qty_8,
          '=""-""&CHAR(10)&""-""&CHAR(10)&""8""&CHAR(10)&""35""&CHAR(10)&""31""':
            v.qty_9,
          '=""-""&CHAR(10)&""-""&CHAR(10)&""10""&CHAR(10)&""36""&CHAR(10)&""32""':
            v.qty_10,
          '=""-""&CHAR(10)&""-""&CHAR(10)&""12""&CHAR(10)&""38""&CHAR(10)&""33""':
            v.qty_11,
          '=""-""&CHAR(10)&""-""&CHAR(10)&""14""&CHAR(10)&""40""&CHAR(10)&""34""':
            v.qty_12,
          '=""-""&CHAR(10)&""-""&CHAR(10)&""16""&CHAR(10)&""42""&CHAR(10)&""35""':
            v.qty_13,
          '=""-""&CHAR(10)&""-""&CHAR(10)&""18""&CHAR(10)&""44""&CHAR(10)&""36""':
            v.qty_14,
          '=""-""&CHAR(10)&""-""&CHAR(10)&""20""&CHAR(10)&""46""&CHAR(10)&""37""':
            v.qty_15,
          '=""-""&CHAR(10)&""-""&CHAR(10)&""-""&CHAR(10)&""48""&CHAR(10)&""38""':
            v.qty_16,
          Qty: v.total_qty,
          "Avg rate": v.avg_rate,
          "Avg Amount": v.avg_amount,
        };
      })
    );
  }

  const totalQuantity = () => {
    let sumQty = locationWiseList.reduce(function (
      previousValue,
      currentValue
    ) {
      return (previousValue += Number(currentValue.total_qty));
    },
      0);
    return sumQty;
  };

  const totalQuantity2 = () => {
    let sumQty = locationWiseList2.reduce(function (
      previousValue,
      currentValue
    ) {
      return (previousValue += Number(currentValue.total_qty));
    },
      0);
    return sumQty;
  };

  return (
    <div className="header_class business_header_oms intransit">
      <div className="row justify-content-between business_class_oms distributor">
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class">
                <CSVLink
                  filename={"LocationWise-Report.csv"}
                  data={excelData}
                  onClick={() => { }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={xlsx}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Location Wise Report
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor">
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12 col-md-3">
              <div className=" d-flex flex-row align-items-baseline">
                <div className="w-100">
                  <Form.Item
                    name="location"
                    label="Location"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 20 }}
                    initialValue={"All"}
                  >
                    <Select
                      size="small"
                      showSearch
                      optionFilterProp="children"
                      autoComplete={"nope"}
                      loading={adminLocation.loading}
                    >
                      <Option value={null}>All</Option>
                      {adminLocation?.payload?.data.map((val, index) => {
                        return (
                          <Option key={val.location_id} value={val.location_id}>
                            {val.location_name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className=" d-flex flex-row align-items-baseline">
                {/* <label className=" no_visibility">none</label> */}
                <div className="w-100" style={{ marginTop: "4px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-btn-btm m-0 "
                  >
                    Generate
                  </Button>
                </div>
                <div className=" d-flex flex-row align-items-baseline">
                  {/* <label className=" no_visibility">none</label> */}
                  <div className="w-100" style={{ marginTop: "4px" }}>
                    <Button
                      type="primary"
                      className="submit-btn-btm m-0 "
                      onClick={onFinish2}
                    >
                      Negative Only
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div
        className="table_content international_order"
        style={{ maxWidth: "1350px" }}
      >
        <div className="overflow">
          {
            loading && (
              <div className="loader" style={{ marginTop: "20px" }}>
                <Spin size="medium" />
              </div>
            )
          }{
            locationWiseList?.length > 0 ? !loading && (
              <Table
                className="sales_report_res_table_intransit"
                ref={tableRef}
                size="small"
                bordered={false}
                type="company"
                headerClassName="abc"
                dataSource={locationWiseList}
                columns={colums}
                loading={locationWise.loading}
                pagination={{ pageSize: 500 }}
                scroll={{ x: "max-content" }}
                summary={(pageData) => {
                  let Size1 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_1 === "" ? 0 : currentValue.qty_1
                    ));
                  },
                    0);
                  let Size2 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_2 === "" ? 0 : currentValue.qty_2
                    ));
                  },
                    0);
                  let Size3 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_3 === "" ? 0 : currentValue.qty_3
                    ));
                  },
                    0);
                  let Size4 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_4 === "" ? 0 : currentValue.qty_4

                    ));
                  },
                    0);
                  let Size5 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_5 === "" ? 0 : currentValue.qty_5
                    ));
                  },
                    0);
                  let Size6 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_6 === "" ? 0 : currentValue.qty_6
                    ));
                  },
                    0);
                  let Size7 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_7 === "" ? 0 : currentValue.qty_7
                    ));
                  },
                    0);
                  let Size8 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_8 === "" ? 0 : currentValue.qty_8
                    ));
                  },
                    0);
                  let Size9 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_9 === "" ? 0 : currentValue.qty_9
                    ));
                  },
                    0);
                  let Size10 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_10 === "" ? 0 : currentValue.qty_10
                    ));
                  },
                    0);
                  let Size11 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_11 === "" ? 0 : currentValue.qty_11
                    ));
                  },
                    0);
                  let Size12 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_12 === "" ? 0 : currentValue.qty_12
                    ));
                  },
                    0);
                  let Size13 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_13 === "" ? 0 : currentValue.qty_13
                    ));
                  },
                    0);
                  let Size14 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_14 === "" ? 0 : currentValue.qty_14
                    ));
                  },
                    0);
                  let Size15 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_15 === "" ? 0 : currentValue.qty_15
                    ));
                  },
                    0);
                  let Size16 = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.qty_16 === "" ? 0 : currentValue.qty_16
                    ));
                  },
                    0);
                  let Quantity = pageData.reduce(function (
                    previousValue,
                    currentValue
                  ) {
                    return (previousValue += parseFloat(
                      currentValue.total_qty
                    ));
                  },
                    0);
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity()).format("0,00,0")}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size1.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size2.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size3.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size4.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size5.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size6.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size7.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size8.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size9.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size10.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size11.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size12.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size13.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size14.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size15.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Size16.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <p style={{ textAlign: "right" }} className="totalquantity">
                            {Quantity.toLocaleString("en-US")}
                          </p>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            ) : (
              locationWiseList2?.length > 0 && !loading ? (
                <Table
                  className="sales_report_res_table_negative_inventory"
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  headerClassName="abc"
                  dataSource={locationWiseList2}
                  columns={colums1}
                  loading={negativeInventorylocationWise.loading}
                  pagination={{ pageSize: 500 }}
                  scroll={{ x: "max-content" }}
                  summary={(pageData) => {
                    let Size1 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_1 === "" ? 0 : currentValue.qty_1
                      ));
                    },
                      0);
                    let Size2 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_2 === "" ? 0 : currentValue.qty_2
                      ));
                    },
                      0);
                    let Size3 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_3 === "" ? 0 : currentValue.qty_3
                      ));
                    },
                      0);
                    let Size4 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_4 === "" ? 0 : currentValue.qty_4

                      ));
                    },
                      0);
                    let Size5 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_5 === "" ? 0 : currentValue.qty_5
                      ));
                    },
                      0);
                    let Size6 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_6 === "" ? 0 : currentValue.qty_6
                      ));
                    },
                      0);
                    let Size7 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_7 === "" ? 0 : currentValue.qty_7
                      ));
                    },
                      0);
                    let Size8 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_8 === "" ? 0 : currentValue.qty_8
                      ));
                    },
                      0);
                    let Size9 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_9 === "" ? 0 : currentValue.qty_9
                      ));
                    },
                      0);
                    let Size10 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_10 === "" ? 0 : currentValue.qty_10
                      ));
                    },
                      0);
                    let Size11 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_11 === "" ? 0 : currentValue.qty_11
                      ));
                    },
                      0);
                    let Size12 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_12 === "" ? 0 : currentValue.qty_12
                      ));
                    },
                      0);
                    let Size13 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_13 === "" ? 0 : currentValue.qty_13
                      ));
                    },
                      0);
                    let Size14 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_14 === "" ? 0 : currentValue.qty_14
                      ));
                    },
                      0);
                    let Size15 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_15 === "" ? 0 : currentValue.qty_15
                      ));
                    },
                      0);
                    let Size16 = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.qty_16 === "" ? 0 : currentValue.qty_16
                      ));
                    },
                      0);
                    let Quantity = pageData.reduce(function (
                      previousValue,
                      currentValue
                    ) {
                      return (previousValue += parseFloat(
                        currentValue.total_qty
                      ));
                    },
                      0);
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell colSpan={2}>Total Quantity : {numeral(totalQuantity2()).format("0,00,0")}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell></Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size1.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size2.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size3.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size4.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size5.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size6.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size7.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size8.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size9.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size10.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size11.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size12.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size13.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size14.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size15.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Size16.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell>
                            <p style={{ textAlign: "right" }} className="totalquantity">
                              {Quantity.toLocaleString("en-US")}
                            </p>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
              ) : (
                !loading && (
                  <div className="no-Data">
                    <h1>No Data</h1>
                  </div>
                )
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default LocationWise;
